@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@500&display=swap');
:root {
    --main-color: rgb(255, 115, 0);
}


/******************** Accueil ***********************/

.app {
    width: 100%;
    min-height: 100vh;
    background-color: #eae6df;
}

.accueil {
    position: fixed;
    top: 2%;
    right: 5%;
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 1.5em;
    background-color: #eae6df;
    z-index: 3;
    font-size: 0.8em;
}

@media screen and (min-width: 768px) {
    .accueil {
        font-size: 1em;
    }
}

.accueil:hover {
    cursor: pointer;
    color: black;
    text-decoration: underline var(--main-color) 2px;
}


/* home */

.home {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 10vh;
    font-family: 'Cerebri', sans-serif;
}

.presentation {
    width: 100%;
    height: 20%;
    text-align: center;
}

.identite {
    width: 100%;
    height: 50%;
    display: flex;
    padding-bottom: 10%;
}

.prenom_nom {
    width: 70%;
    height: 100%;
    margin: auto;
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 2.3em;
    font-weight: bold;
    color: var(--main-color);
    display: flex;
}

@media screen and (min-width: 360px) {
    .prenom_nom {
        font-size: 2.7em;
    }
}

@media screen and (min-width: 768px) {
    .prenom_nom {
        font-size: 4.5em;
        width: 60%;
    }
}

.p_prenom_nom {
    margin: auto;
}

@media screen and (min-width: 1025px) {
    .p_prenom_nom {
        margin-right: 30%;
    }
}

.photo {
    width: 30%;
    height: 100%;
    display: flex;
}

@media screen and (min-width: 768px) {
    .photo {
        width: 40%;
    }
}

.img_photo {
    margin: auto;
    width: 70%;
    border: 2px solid grey;
}

@media screen and (min-width: 768px) {
    .img_photo {
        width: 50%;
    }
}

@media screen and (min-width: 1025px) {
    .img_photo {
        width: 30%;
        margin-left: 10%;
    }
}

.liens {
    width: 100%;
    height: 30%;
}

.fichiers {
    display: flex;
    height: 50%;
}

.div_telecharger {
    width: 50%;
    height: 40px;
    display: flex;
}

.bouton_telecharger {
    margin: auto;
    width: 80%;
    height: 100%;
    background-color: black;
    color: white;
    display: flex;
}

@media screen and (min-width: 768px) {
    .bouton_telecharger {
        width: 60%;
    }
}

.bouton_telecharger:hover {
    background-color: white;
    border: solid 1px grey;
    color: black;
    cursor: pointer;
    text-decoration: none;
}

.titre_bouton_telecharger {
    margin: auto;
    font-size: 0.7em;
}

@media screen and (min-width: 350px) {
    .titre_bouton_telecharger {
        font-size: 1em;
    }
}

.reseaux {
    height: 50%;
}

.div_lien {
    text-align: center;
}

.lien_reseau {
    width: 50%;
    height: 100%;
    display: inline-block;
}

.lien_reseau_linkedin {
    text-align: right;
}

.lien_reseau_github {
    text-align: left;
}

.img_reseau {
    width: 40px;
    transition: transform 500ms;
}

@media screen and (min-width: 768px) {
    .img_reseau {
        width: 60px;
    }
}

.img_reseau:hover {
    transform: rotate(360deg);
}


/* about me */

.about_me {
    height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: 'Lato', sans-serif;
}

@media screen and (min-width: 768px) {
    .about_me {
        flex-direction: row;
    }
}

.texte_about_me {
    width: 100%;
    height: 50%;
    display: flex;
    padding: 5%;
    text-align: justify;
}

@media screen and (min-width: 768px) {
    .texte_about_me {
        width: 60%;
        height: 100%;
        font-size: 2em;
    }
}

.p_texte_about_me {
    margin: auto;
}

.barres {
    width: 100%;
    height: 50%;
    display: flex;
}

@media screen and (min-width: 768px) {
    .barres {
        width: 40%;
        height: 100%;
    }
}

.p_barres {
    width: 80%;
    margin: auto;
    padding: 0 0 5% 5%;
    background-color: white;
    box-shadow: 10px 5px 5px black;
}

@media screen and (min-width: 768px) {
    .p_barres {
        width: 80%;
    }
}

.div_comp {
    padding-bottom: 5%;
}

.competences {
    margin-bottom: 2%;
}

.div_rectangles {
    width: 90%;
    display: flex;
    justify-content: space-between;
}

.rectangle {
    width: 10%;
    height: 3px;
    background-color: black;
}

.op {
    opacity: 0.3;
}


/* projets */

.projets {
    width: 100%;
    min-height: 100vh;
    padding-top: 2vh;
    font-family: 'Lato', sans-serif;
}

@media screen and (min-width: 768px) {
    .projets {
        padding-top: 5vh;
    }
}

.titre_projets {
    font-style: italic;
    font-size: 1.3em;
    text-decoration: underline var(--main-color) 2px;
    padding-top: 20%;
    padding-bottom: 2%;
}

@media screen and (min-width: 768px) {
    .titre_projets {
        padding-top: 10%;
    }
}

.row {
    margin: 0 !important;
}

.col-md-4 {
    padding: 0 !important;
}

.projet {
    height: 20vh;
}

@media screen and (min-width: 1025px) {
    .projet {
        height: 35vh;
    }
}

.img_projet {
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
}

.img_projet:hover {
    filter: none;
    -webkit-filter: none;
}

.div_autres_projets {
    padding: 0 5%;
    text-align: justify;
}

.titre_projets_php {
    text-decoration: underline var(--main-color) 2px;
}


/* parcours */

.parcours {
    width: 100%;
    padding-top: 7vh;
    font-family: 'Lato', sans-serif;
    min-height: 25vh;
}

@media screen and (min-width: 768px) {
    .parcours {
        flex-direction: row-reverse;
        height: 50vh;
    }
}

@media screen and (min-width: 1025px) {
    .parcours {
        height: 100vh;
    }
}

.texte_parcours {
    display: flex;
    height: 100%;
}

.p_texte_parcours {
    margin: auto;
    text-align: justify;
}

@media screen and (min-width: 768px) {
    .p_texte_parcours {
        font-size: 2em;
    }
}

.image {
    height: 100%;
    display: none;
}

@media screen and (min-width: 768px) {
    .image {
        display: flex;
    }
}

.img_parcours {
    width: 80%;
    margin: auto;
}

.image2 {
    height: 100%;
    text-align: center;
    padding: 5% 0;
}

@media screen and (min-width: 768px) {
    .image2 {
        display: none;
    }
}

.bouton_lien {
    width: 200px;
    height: 30px;
    background-color: black;
    color: white;
    display: flex;
}

@media screen and (min-width: 768px) {
    .bouton_lien {
        width: 340px;
        height: 50px;
        font-size: 0.8em;
    }
}

.bouton_lien:hover {
    background-color: white;
    color: black;
    border: solid 1px grey;
    cursor: pointer;
    text-decoration: none;
}

.p_bouton_lien {
    margin: auto;
    font-size: 0.8em;
}

.div_bouton_autres_projets {
    padding-top: 5%;
    padding-left: 5%;
}

@media screen and (min-width: 768px) {
    .div_bouton_autres_projets {
        font-size: 2em;
    }
}


/* contact */

.contact {
    min-height: 20vh;
    font-family: 'Lato', sans-serif;
    font-size: 0.8em;
}

@media screen and (min-width: 768px) {
    .contact {
        font-size: 1.3em;
    }
}

@media screen and (min-width: 1024px) {
    .contact {
        font-size: 2em;
        line-height: 20vh;
    }
}

.lien_contact {
    border-bottom: solid 1px;
}

.lien_contact:hover {
    text-decoration: none;
    background-color: #007BFF;
    color: white;
}


/******************** Autres projets ***********************/

.bodyAutres_projets {
    background-color: #eae6df;
    width: 100vw;
    min-height: 100vh;
}

.titre_autres_projets {
    text-decoration: underline var(--main-color) 2px;
}

.div_autre_projet {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 768px) {
    .div_autre_projet {
        flex-direction: row;
        height: 30vh;
    }
    .div_autre_projet2 {
        flex-direction: row-reverse;
    }
}

@media screen and (min-width: 1025px) {
    .div_autre_projet {
        height: 50vh;
    }
}

.texte_autre_projet {
    width: 100%;
    height: 50%;
    display: flex;
    border: solid 1px grey;
}

@media screen and (min-width: 768px) {
    .texte_autre_projet {
        width: 50%;
        height: 100%;
    }
}

.div_texte_autre_projet {
    margin: auto;
}

.lien_autre_projet {
    width: 100%;
    height: 50%;
    background-color: red;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
}

@media screen and (min-width: 768px) {
    .lien_autre_projet {
        width: 50%;
        height: 100%;
    }
}

.lien_autre_projet:hover {
    filter: none;
    -webkit-filter: none;
}

.a_lien_autre_projet {
    width: 100%;
    height: 100%;
}

.img_lien_autre_projet {
    width: 100%;
    height: 100%;
}


/******************** Contact ***********************/

.bodyContact {
    background-color: #eae6df;
    min-height: 95vh;
}

.titreContact {
    text-decoration: underline var(--main-color) 2px;
}


/******************** Footer ***********************/

.bodyFooter {
    height: 7vh;
    width: 100%;
    background-color: #eae6df;
}

.texte_footer {
    font-size: 0.6em;
}

@media screen and (min-width: 768px) {
    .texte_footer {
        font-size: 1em;
    }
}

.chevron {
    width: 30px;
    border: solid 1px black;
    color: white;
}

.chevron:hover {
    cursor: pointer;
    background-color: white;
}


/******************** Header ***********************/

.bodyHeader {
    position: fixed;
    height: 7vh;
    width: 100%;
    font-family: 'Cerebri', sans-serif;
    font-size: 0.8em;
    z-index: 2;
}

.navigation {
    width: 100%;
    height: 7vh;
    background-color: #eae6df;
    padding-top: 2%;
}

.nav_about_me,
.nav_projets,
.nav_parcours {
    visibility: hidden;
}

.nav_about_me,
.nav_projets,
.nav_parcours,
.nav_contact {
    width: 10vw;
    color: black;
    font-size: 0.7em;
    padding: 2%;
}

@media screen and (min-width: 1025px) {
    .nav_about_me,
    .nav_projets,
    .nav_parcours,
    .nav_contact {
        visibility: visible;
        font-size: 1em;
    }
}

@media screen and (max-width: 1024px) {
    .nav_contact {
        visibility: visible;
        font-size: 1em;
        position: fixed;
        top: 1%;
        left: 5%;
    }
}

.nav_about_me:hover,
.nav_projets:hover,
.nav_parcours:hover,
.nav_contact:hover {
    color: black;
    text-decoration: line-through var(--main-color) 2px;
}

.scroll {
    text-decoration: line-through var(--main-color) 2px;
}


/******************** Not Found ***********************/

.bodyNotFound {
    background-color: #eae6df;
    min-height: 93vh;
    display: flex;
}

.titreNotFound {
    margin: auto;
}


/******************** Parcours ***********************/

.bodyParcours {
    background-color: #eae6df;
    width: 100%;
    min-height: 100vh;
}

.div_titres_parcours {
    position: fixed;
    width: 100%;
    height: 7vh;
    background-color: #eae6df;
    z-index: 2;
    display: flex;
}

.titre_parcours {
    width: 50%;
    height: 100%;
    display: flex;
}

.titre_parcours_detail {
    margin: auto;
    color: black;
    font-family: 'Cerebri', sans-serif;
}

.titre_parcours_detail:hover {
    color: black;
    text-decoration: line-through var(--main-color) 2px;
}

#exp_pro {
    padding-top: 8vh;
    text-decoration: underline var(--main-color) 2px;
}

.div_exp_pro_tour {
    padding: 5%;
}

@media screen and (min-width: 768px) {
    .div_exp_pro_tour {
        width: 50%;
        padding: 2%;
        margin: auto;
    }
}

.div_exp_pro {
    border: solid 1px black;
    background-color: white;
    padding: 2%;
    box-shadow: 10px 5px 5px black;
}

.h6_exp_pro {
    color: grey;
}

.p_parcours {
    margin: 0;
}


/******************** Formation ***********************/

#formation {
    padding-top: 8vh;
    text-decoration: underline var(--main-color) 2px;
}

.div_formation {
    border: solid 1px grey;
    border-radius: 15px;
    background-color: white;
    padding: 2%;
    box-shadow: 10px 5px 5px black;
}